import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../components/Layouts/layout'
import SEO from '../../components/seo'
import PersonRow from '../../components/PersonRow/PersonRow'
import Wendy from '../../imageComponents/wendyAcheson'

const LeadershipPage = () => {
  const intl = useIntl()

  return (
    <Layout
      breadcrumb={{
        text: <FormattedMessage id='nav.about' />,
        path: '/about/'
      }}
      header={<FormattedMessage id='leadership.header01' />}
    >
      <SEO
        description={intl.formatMessage({ id: 'metaTags.aboutDescription' })}
        lang={intl.locale}
        title={intl.formatMessage({ id: 'leadership.header01' })}
      />
      <Container>
        <Row>
          <Col>
            <p>
              <FormattedMessage id='leadership.content01' />
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <PersonRow image={<Wendy />} size='large'>
              <h2 className='underline'>
                <span className='sm thin'>
                  <FormattedMessage id='leadership.ceoTitle' />
                </span>
                <br />
                <span className='thin'>Wendy </span>Moir
              </h2>
              <p>
                <FormattedMessage id='leadership.ceoBio' />
              </p>
            </PersonRow>
          </Col>
        </Row>

        <Row className='pageRow'>
          <Col>
            <h2 class='underline'>
              <FormattedMessage id='leadership.header02' />
            </h2>
            <ul className='blockList'>
              <li>
                <h3>
                  Marc Spector{' '}
                  <span className='sm thin'>
                    <FormattedMessage id='leadership.title01' />
                  </span>
                </h3>
              </li>
              <li>
                <h3>
                  Glen Medeiros{' '}
                  <span className='sm thin'>
                    <FormattedMessage id='leadership.title04' />
                  </span>
                </h3>
              </li>

              {intl.locale === 'en' && (
                <li>
                  <h3>
                    Siloni Waraich{' '}
                    <span className='sm thin'>
                      <FormattedMessage id='leadership.title08' />
                    </span>
                  </h3>
                </li>
              )}

              <li>
                <h3>
                  James Ryu{' '}
                  <span className='sm thin'>
                    <FormattedMessage id='leadership.title05' />
                  </span>
                </h3>
              </li>

              <li>
                <h3>
                  Tess Lin{' '}
                  <span className='sm thin'>
                    <FormattedMessage id='leadership.title02' />
                  </span>
                </h3>
              </li>

              <li>
                <h3>
                  Lisa Kerr{' '}
                  <span className='sm thin'>
                    <FormattedMessage id='leadership.title03' />
                  </span>
                </h3>
              </li>

              {intl.locale === 'en' && (
                <li>
                  <h3>
                    Sarah Hobbs{' '}
                    <span className='sm thin'>
                      <FormattedMessage id='leadership.title07' />
                    </span>
                  </h3>
                </li>
              )}
            </ul>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default LeadershipPage
